import { ChangeEvent, useState } from "react";
import "./App.css";

function App() {
  const [message, setMessage] = useState(localStorage.getItem("message") || "");
  const [shouldShowSuccess, setShouldShowSuccess] = useState(false);
  const [shouldShowFailure, setShouldShowFailure] = useState(false);
  const [showConfirmView, setShowConfirmView] = useState(false);
  const [pending, setPending] = useState(false);

  const showSuccess = () => {
    setShouldShowSuccess(true);
    setTimeout(() => {
      setShouldShowSuccess(false);
    }, 3000);
  };
  const showFailure = () => {
    setShouldShowFailure(true);
    setTimeout(() => {
      setShouldShowFailure(false);
    }, 3000);
  };
  const send = async () => {
    setPending(true);
    try {
      await fetch(
        "https://us-central1-sunset-farms-project.cloudfunctions.net/message",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message }),
        }
      );
      showSuccess();
      updateMessage("");
    } catch {
      showFailure();
    }
    setShowConfirmView(false);
    setPending(false);
  };
  const updateMessage = (message: string) => {
    setMessage(message);
    localStorage.setItem("message", message);
  };
  const messageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    updateMessage(e.target.value);
  };
  const messageChunks =
    message.match(
      /(.|[\r\n])((.|[\r\n]){1,319}(\s|$|[\r\n]))|(.|[\r\n]){1,319}/g
    );
  return (
    <div className="App">
      <div className="App-content">
        {showConfirmView && (
          <div className={"App-modal"}>
            <h2>Are you sure you want to send this message?</h2>
            {messageChunks?.map((chunk) => (
              <pre key={chunk} className={"App-confirm-pre"}>
                {chunk}
              </pre>
            ))}
            <button
              onClick={send}
              className={"App-yes-button"}
              disabled={pending}
            >
              Yes
            </button>
            <button
              onClick={() => setShowConfirmView(false)}
              className={"App-no-button"}
              disabled={pending}
            >
              No
            </button>
          </div>
        )}
        {!showConfirmView && (
          <>
            {shouldShowFailure && (
              <p className={"App-failure"}>
                Something went wrong. Please try again later.
              </p>
            )}
            {shouldShowSuccess && <p className={"App-success"}>Message sent</p>}
            <textarea
              className={"App-text-area"}
              placeholder={"Send message to whole UO"}
              onChange={messageChange}
              value={message}
            />
            <button
              onClick={() => setShowConfirmView(true)}
              className={"App-send-button"}
              disabled={!message}
            >
              Send to everyone
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
